import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as CalendarIcon } from 'assets/images/icons/calendarIcon.svg';
import cn from 'classnames';
import { ar, de, enUS, es, fr, hi, it, ka, kk, ru, tr, uz } from 'date-fns/locale';
import { formatNumber } from 'helpers/formatNumber';
import useWindowSize from 'hooks/useWindowSize';
import i18next, { t } from 'i18next';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';

import SwitchInput from 'components/Custom/SwitchInput';
import EmptyState from 'components/EmptyState';
import Multiselect from 'components/Multiselect';
import PaginationComponent from 'components/PaginationComponent';

import s from './AccrualsTable.module.scss';
import { DISPLAYED_KEY_NAMES, columns } from './columns';

const locales = {
  ar: ar,
  de: de,
  en: enUS,
  es: es,
  fr: fr,
  hi: hi,
  it: it,
  ka: ka,
  kk: kk,
  ru: ru,
  tr: tr,
  uz: uz,
};

const AccrualsTable = ({
  accruals,
  filter,
  totalPages,
  totalAmount,
  setSort: setExternalSort,
  setFilter,
  handleSaveFilters,
}) => {
  const { width: windowWidth } = useWindowSize();
  const language = useSelector((state) => state.Layout.language);
  const [accrualsTypes, setAccrualsTypes] = useState({
    passive: false,
    product: false,
    referral: true,
  });
  const [sort, setSort] = useState({
    field: '',
    order: 'asc',
  });
  const isMobile = windowWidth <= 770;
  const options = [
    { label: t('tables_title.nulled'), value: 'nulled' },
    { label: t('tables_title.sent'), value: 'sent' },
    { label: t('tables_title.waiting'), value: 'waiting' },
    { label: t('tables_title.error'), value: 'error' },
    { label: t('tables_title.probably_sent'), value: 'probably_sent' },
  ];
  const [isStartDatePickerVisible, setIsStartDatePickerVisible] = useState(false);
  const [isEndDatePickerVisible, setIsEndDatePickerVisible] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: new Date(),
    to: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  });
  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);

  const itemKeys = Object.keys(accruals[0] ?? {}).filter((key) => !!DISPLAYED_KEY_NAMES[key]);

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === 'sort') {
      setExternalSort({
        field: sortField,
        order: sortOrder.toUpperCase(),
      });
    }
  };

  const handleChangeAccrualsTypes = useCallback(
    (value, type) => {
      const types = accrualsTypes;
      setAccrualsTypes((prev) => ({
        ...prev,
        [type]: value,
      }));
      types[type] = value;

      if (!types.referral && !types.passive) {
        setFilter((prev) => ({
          ...prev,
          partner_id: '',
        }));
      }

      if (!types.product) {
        setFilter((prev) => ({
          ...prev,
          user_package_number: '',
        }));
      }

      setFilter((prev) => ({
        ...prev,
        accrual_type__in: Object.entries(types)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => key),
      }));
    },
    [filter, accrualsTypes, setFilter],
  );

  const onSortChange = (event) => {
    const sortChangeType = event.target.name;
    setSort((prev) => ({ ...prev, [sortChangeType]: event.target.value }));
  };

  const handleDateSelect = useCallback(
    (range) => {
      setIsStartDatePickerVisible(false);
      setIsEndDatePickerVisible(false);

      setDateRange({ from: range?.from, to: range?.to });
      setFilter((prev) => ({
        ...prev,
        startDate: range?.from,
        endDate: range?.to,
      }));
    },
    [setFilter],
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startDatePickerRef.current &&
        event.target instanceof Node &&
        !startDatePickerRef.current.contains(event.target)
      ) {
        setIsStartDatePickerVisible(false);
      }
      if (
        endDatePickerRef.current &&
        event.target instanceof Node &&
        !endDatePickerRef.current.contains(event.target)
      ) {
        setIsEndDatePickerVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      setDateRange({ from: null, to: null });
    };
  }, []);

  useEffect(() => {
    if (isMobile && sort.field) {
      setExternalSort({ [sort.field]: sort.order.toUpperCase() });
    }
  }, [isMobile, setExternalSort, sort]);

  useEffect(() => {
    const keysToCheck = Object.keys(accrualsTypes);
    const newFilter = keysToCheck.reduce((acc, key) => {
      acc[key] = filter?.accrual_type__in?.includes(key);
      return acc;
    }, {});

    setAccrualsTypes(newFilter);
  }, [filter]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.filters}>
          <div className={s.switches}>
            <SwitchInput
              label="common_referral_accruals"
              defaultChecked={accrualsTypes.referral}
              onChange={(value) => handleChangeAccrualsTypes(value, 'referral')}
              value={accrualsTypes.referral}
            />
            <SwitchInput
              label="common_passive_accruals"
              defaultChecked={accrualsTypes.passive}
              onChange={(value) => handleChangeAccrualsTypes(value, 'passive')}
              value={accrualsTypes.passive}
            />
            <SwitchInput
              label="common_product_accruals"
              defaultChecked={accrualsTypes.product}
              onChange={(value) => handleChangeAccrualsTypes(value, 'product')}
              value={accrualsTypes.product}
            />

            <div className={cn(s.saveBtn, s.hideModal)} onClick={handleSaveFilters}>
              {t('common_save_filters')}
            </div>
          </div>
          <div className={s.row}>
            <div className={s.inputs}>
              <div className={s.block}>
                <span className={s.label}>{t('common_chose_period')}</span>
                <div className={s.dateRangePicker}>
                  <button
                    className={s.selectDateButton}
                    onClick={() => setIsStartDatePickerVisible(!isStartDatePickerVisible)}>
                    {!!dateRange?.from
                      ? `${moment(dateRange?.from).format('DD.MM.YYYY')}`
                      : moment().subtract(1, 'years').format('DD.MM.YYYY')}
                    <CalendarIcon />
                  </button>
                  {isStartDatePickerVisible && (
                    <div className={s.datePickerDropdown} ref={startDatePickerRef}>
                      <DayPicker
                        mode="single"
                        selected={dateRange?.from}
                        onSelect={(date) => handleDateSelect({ from: date, to: dateRange?.to })}
                        captionLayout="dropdown-buttons"
                        fromYear={moment().subtract(10, 'years').year()}
                        toYear={moment().add(10, 'years').year()}
                        defaultMonth={dateRange?.from}
                        locale={locales[i18next.language || 'en']}
                      />
                    </div>
                  )}
                  <button
                    className={s.selectDateButton}
                    onClick={() => setIsEndDatePickerVisible(!isEndDatePickerVisible)}>
                    {!!dateRange?.to
                      ? `${moment(dateRange?.to).format('DD.MM.YYYY')}`
                      : moment().format('DD.MM.YYYY')}
                    <CalendarIcon />
                  </button>
                  {isEndDatePickerVisible && (
                    <div className={s.datePickerDropdown} ref={endDatePickerRef}>
                      <DayPicker
                        mode="single"
                        selected={dateRange?.to}
                        onSelect={(date) => handleDateSelect({ from: dateRange?.from, to: date })}
                        captionLayout="dropdown-buttons"
                        fromYear={moment().subtract(10, 'years').year()}
                        toYear={moment().add(10, 'years').year()}
                        defaultMonth={dateRange?.to}
                        locale={locales[i18next.language || 'en']}
                      />
                    </div>
                  )}
                </div>
              </div>
              {(accrualsTypes?.referral || accrualsTypes?.passive) && (
                <div className={s.block}>
                  <span className={s.label}>{t('common_partner_id')}</span>
                  <Input
                    placeholder={t('input_placeholder_search_partner')}
                    className={s.searchInput}
                    onChange={(e) => setFilter((prev) => ({ ...prev, partner_id: e.target.value }))}
                  />
                </div>
              )}
            </div>
            <div className={s.inputs}>
              {accrualsTypes?.product && (
                <div className={s.block}>
                  <span className={s.label}>{t('tables_title.package_number')}</span>
                  <Input
                    placeholder={t('input_placeholder_search_package_number')}
                    className={s.searchInput}
                    onChange={(e) =>
                      setFilter((prev) => ({ ...prev, user_package_number: e.target.value }))
                    }
                  />
                </div>
              )}

              <div className={s.block}>
                <span className={s.label}>{t('tables_title.status')}</span>
                <Multiselect
                  options={options}
                  placeholder={t('accruals_status_placeholder')}
                  defaultSelected={filter?.status__in}
                  onChange={(value) => setFilter((prev) => ({ ...prev, status__in: value }))}
                />
              </div>
            </div>
          </div>

          {isMobile && (
            <div className={s.inputs}>
              <Input type="select" value={sort.field} onChange={onSortChange} name="field">
                <option disabled value="">
                  {t('project_accruals_sorting_field')}
                </option>
                {itemKeys &&
                  itemKeys.map((key, index) => (
                    <option key={`${key}_${index}`} value={key}>
                      {DISPLAYED_KEY_NAMES[key]}
                    </option>
                  ))}
              </Input>
              <Input type="select" onChange={onSortChange} name="order">
                <option value="asc">{t('project_accruals_sorting_order_asc')}</option>
                <option value="desc">{t('project_accruals_sorting_order_desc')}</option>
              </Input>

              <div className={s.saveBtn} onClick={handleSaveFilters}>
                {t('common_save_filters')}
              </div>
            </div>
          )}
        </div>
        <div className={s.total}>
          {t('common_total')}
          <span className={s.amount}>{formatNumber(totalAmount)} USDT</span>
        </div>
      </div>
      <BootstrapTable
        keyField="id"
        data={accruals || []}
        columns={columns(t, language)}
        headerClasses="table-head"
        wrapperClasses="table-responsive"
        classes="table__adaptive_bordered_between text-center"
        responsive
        remote
        onTableChange={handleTableChange}
      />
      {accruals?.length === 0 && <EmptyState />}
      <PaginationComponent
        active={filter?.pageNumber}
        setPagination={setFilter}
        total={totalPages}
      />
    </div>
  );
};

export default AccrualsTable;
