import React, { useCallback, useEffect, useState } from 'react';

import { t } from 'i18next';
import moment from 'moment';
import toast from 'react-hot-toast';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import AccrualsGraph from './components/accruals-graph/AccrualsGraph';
import AccrualsTable from './components/accruals-table/AccrualsTable';
import Breadcrumbs from 'components/Common/Breadcrumb';

import { get, put } from '../../helpers/api_helper';
import { fetchAccrualsGraphData, fetchTotalAccruals } from '../../store/accruals/actions';

const TotalAccrualsPage = () => {
  const totalAccruals = useSelector((state) => state.Accruals.accruals);
  const accrualsGraphData = useSelector((state) => state.Accruals.accrualsGraphData);
  const [activeYears, setActiveYears] = React.useState(null);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});

  const [sort, setSort] = useState({ field: 'payment_date', order: 'ASC' });

  const handleGetFilters = useCallback(async () => {
    const res = await get('/user/filters');
    const graphYears = await get('/calculations/graphic/default_dates');

    if (res) {
      setFilters((prevState) => ({
        ...prevState,
        ...res,
        status__in: res.status__in?.length
          ? res.status__in
          : ['error', 'waiting', 'sent', 'nulled'],
        accrual_type__in: res.accrual_type__in || ['referral'],
        startDate: new Date(),
        endDate: new Date().setFullYear(
          new Date().getFullYear() + 1,
          new Date().getMonth(),
          new Date().getDate(),
        ),
      }));
    } else {
      setFilters({
        startDate: new Date(),
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        accrual_type__in: ['referral'],
        pageNumber: 1,
        partner_id: '',
        user_package_number: '',
        size: 25,
        status__in: ['error', 'waiting', 'sent', 'nulled'],
      });
    }

    if (graphYears) {
      setActiveYears({
        startDate: new Date(new Date(graphYears.from_year) || new Date()),
        endDate: new Date(
          graphYears.to_year || new Date().setFullYear(new Date().getFullYear() + 1),
        ),
      });
    }
  }, []);

  const handleSaveFilters = useCallback(async () => {
    const res = await put('/user/filters', {
      status__in: filters.status__in,
      accrual_type__in: filters?.accrual_type__in,
    });

    if (res) {
      toast.success(t('toast_filters_saved'));
    }
  }, [filters]);

  useEffect(() => {
    if (filters?.startDate && filters?.endDate) {
      dispatch(
        fetchTotalAccruals({
          filters: {
            payment_date__gt: moment(filters.startDate).subtract(1, 'day').format('yyyy-MM-DD'),
            payment_date__lt: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            accrual_type__in: filters?.accrual_type__in,
            status__in: filters.status__in,
            ...(filters?.partner_id && { partner_id: filters?.partner_id }),
            ...(filters?.user_package_number && {
              user_package_number: filters?.user_package_number,
            }),
          },
          pagination: {
            take: filters?.size,
            skip: (filters?.pageNumber - 1) * filters?.size,
          },
          orderBy: sort.field,
          order: sort.order,
        }),
      );
      localStorage.setItem('accruals_filters', JSON.stringify(filters));
    }
  }, [dispatch, filters, sort]);

  useEffect(() => {
    if (activeYears?.startDate) {
      dispatch(
        fetchAccrualsGraphData({
          fromYear: moment(activeYears.startDate).format('yyyy'),
          ...(activeYears.endDate && { toYear: moment(activeYears.endDate).format('yyyy') }),
        }),
      );
    }
  }, [activeYears, dispatch]);

  useEffect(() => {
    handleGetFilters();
  }, [handleGetFilters]);

  return (
    <div className="page-content  min-vh-100">
      <MetaTags>
        <title>{t('accruals_meta_title')}</title>
      </MetaTags>
      <Container fluid className="mobile-no-padding">
        <Breadcrumbs
          title="Metrics"
          hasBreadcrumbItem={false}
          breadcrumbItem={t('sidebar_accruals_label')}
        />

        <AccrualsGraph
          accrualsGraphData={accrualsGraphData}
          activeYears={activeYears}
          setActiveYear={setActiveYears}
        />

        <AccrualsTable
          accruals={totalAccruals?.items || []}
          filter={filters}
          totalPages={totalAccruals?.total_pages_count}
          setFilter={setFilters}
          setSort={setSort}
          totalAmount={totalAccruals?.total_sum || 0}
          handleSaveFilters={handleSaveFilters}
        />
      </Container>
    </div>
  );
};

export default TotalAccrualsPage;
