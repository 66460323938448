import React, { useEffect } from 'react';

import useTranslation from 'hooks/useTranslation';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { getProjectsList as onGetProjectsList } from 'store/actions';

import { convertLocale } from '../../helpers/convertLocation';
import Icon from '../Icon';

const SidebarProjectsCategories = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Profile.user);
  const t = useTranslation();

  useEffect(() => {
    dispatch(onGetProjectsList(convertLocale('en')));
  }, [dispatch]);

  const { projectsList } = useSelector((state) => ({
    projectsList: state.Project.projectsList,
  }));

  if (isEmpty(projectsList) && !Array.isArray(projectsList)) return null;

  return (
    <React.Fragment>
      <li key="projects">
        <Link to="#" className="sidebar-navlink li__first_dropdown has-arrow li__sidebar">
          <Icon name="project" />
          <span>{t('sidebar_projects_label')}</span>
        </Link>
        <ul className="sub-menu">
          {map(projectsList, (project) => {
            if (project?.slug?.includes('investor_pro') && user?.level >= 4) {
              return (
                <li key={project.id}>
                  <NavLink to={`/projects/${project.slug}`} key={project.slug}>
                    <span>{t(`strapi_${project.slug}`)}</span>
                  </NavLink>
                </li>
              );
            }
            if (!project?.slug?.includes('investor_pro')) {
              return (
                <li key={project.id}>
                  <NavLink to={`/projects/${project.slug}`} key={project.slug}>
                    <span>{t(`strapi_${project.slug}`)}</span>
                  </NavLink>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </li>
    </React.Fragment>
  );
};

SidebarProjectsCategories.propTypes = {
  t: PropTypes.any,
};

export default withRouter(SidebarProjectsCategories);
