import { t } from 'i18next';
import toast from 'react-hot-toast';
import { put, takeEvery } from 'redux-saga/effects';

import userService from '../../services/userService';

import { registerUserFailed } from '../auth/register/actions';
import { SEND_CONFIRM_CODE, SEND_CONFIRM_CODE_RESEND } from './actionTypes';
import { sendConfirmCodeError, sendConfirmCodeSuccessful } from './actions';

function* sendConfirmCode({ payload: { type, code, value, history, email } }) {
  try {
    const response = yield userService.sendConfirmCode({
      confirmation_type: type,
      code,
      value,
      user_email: email,
    });
    if (response) {
      switch (type) {
        case 'registration':
          toast.success(t('auth_verify_your_email_success'));
          break;
        case 'reset_password':
          toast.success(t('auth_reset_password_success'));
          break;
        case 'confirm_wallet':
          toast.success(t('auth_change_wallet_success'));
      }

      if (type === 'reset_password') {
        history.push('/login');
        localStorage.removeItem('verifyEmail');
      }

      yield put(sendConfirmCodeSuccessful(true));
    }
  } catch (error) {
    switch (type) {
      case 'registration':
        if (error?.response?.data?.detail || error?.response?.data?.message) {
          yield put(
            sendConfirmCodeError(error?.response?.data?.detail || error?.response?.data?.message),
          );
        } else {
          yield put(sendConfirmCodeError(t('auth_verify_your_email_error')));
        }
        break;
      case 'reset_password':
        if (error?.response?.data?.detail || error?.response?.data?.message) {
          yield put(
            sendConfirmCodeError(error?.response?.data?.detail || error?.response?.data?.message),
          );
        } else {
          yield put(sendConfirmCodeError(t('auth_reset_password_error')));
        }
        break;
      // case 'confirm_wallet':
      //   if (error?.response?.data?.detail) {
      //     yield put(sendConfirmCodeError(error?.response?.data?.detail));
      //   } else {
      //     yield put(sendConfirmCodeError(t('auth_change_wallet_error')));
      //   }
      //   break;
    }
  }
}

function* resendConfirmCode({ payload: { type, value, email } }) {
  try {
    const response = yield userService.resendConfirmCode({
      confirmation_type: type,
      value,
      user_email: email,
    });

    if (response) {
      toast.success(t('auth_verify_resend_success'));
    }
  } catch (error) {
    if (error?.response?.data?.detail || error?.response?.data?.message) {
      yield put(
        sendConfirmCodeError(error?.response?.data?.detail || error?.response?.data?.message),
      );
    } else {
      yield put(sendConfirmCodeError(t('core_unknown_error')));
    }
  }
}

export function* usersSaga() {
  yield takeEvery(SEND_CONFIRM_CODE, sendConfirmCode);
  yield takeEvery(SEND_CONFIRM_CODE_RESEND, resendConfirmCode);
}

export default usersSaga;
